export default [
  {
    title: 'MORE Family Collection',
    link: 'more.co.za',
    type: 'website'
  },
  {
    title: 'MORE Family Collection',
    link: 'facebook.com/morefamilycollection',
    type: 'facebook'
  },
  {
    title: 'MORE Family Collection',
    link: 'instagram.com/morefamilycollection',
    type: 'instagram',
    hashtags: [
      '#MOREFamilyCollection',
      '#OurMOREExperiences'
    ]
  },
  {
    title: 'MORE Family Collection',
    link: 'youtube.com/MoreAfrica',
    type: 'youtube'
  },
  {
    title: 'Lion Sands Game Reserve',
    link: 'instagram.com/lionsandsgamereserve',
    type: 'instagram',
    hashtags: [
      '#LionSandsGameReserve',
      '#MOREFamilyCollection',
      '#LuxurySafariExperience',
      '#AfricanSafari',
      '#OurMOREExperiences'
    ]
  },
  {
    title: 'Marataba Luxury Lodges',
    link: 'instagram.com/maratabaluxurylodges',
    type: 'instagram',
    hashtags: [
      '#MaratabaLuxuryLodges',
      '#MOREFamilyCollection',
      '#LuxurySafariExperience',
      '#AfricanSafari',
      '#OurMOREExperiences'
    ]
  },
  {
    title: 'More Quarters Neighbourhood Hotel',
    link: 'instagram.com/morequarters',
    type: 'instagram',
    hashtags: [
      '#MoreQuarters',
      '#MOREFamilyCollection',
      '#CapeTown',
      '#MotherCity',
      '#CapeTownExperiences',
      '#OurMOREExperiences'
    ]
  },
  {
    title: 'Cape Cadogan Boutique Hotel',
    link: 'instagram.com/capecadogan',
    type: 'instagram',
    hashtags: [
      '#CapeCadoganBoutiqueHotel',
      '#MOREFamilyCollection',
      '#CapeTown',
      '#MotherCity',
      '#CapeTownExperiences',
      '#OurMOREExperiences'
    ]
  },
  {
    title: 'Stanley & Livingstone Boutique Hotel',
    link: 'instagram.com/stanleyandlivingstone',
    type: 'instagram',
    hashtags: [
      '#StanleyAndLivingstoneBoutiqueHotel',
      '#MOREFamilyCollection',
      '#Zimbabwe',
      '#LuxurySafariExperience',
      '#AfricanSafari',
      '#OurMOREExperiences'
    ]
  }
]
