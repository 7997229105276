<template>
  <div class="row m-0 px-lg-4" :class="[isMobile || isTablet ? 'pt-4' : '']">
    <div class="col-12 px-lg-5">
      <div class="row m-0 p-0">
        <div class="col text-left pb-5">
          Become a part of our social family, and start your journey right away! We'll connect with you through rich storytelling, which is built around beautiful images, videos and blogs. Get a taste of how we will enrich your life...
        </div>
      </div>
      <div class="row m-0 p-0" v-for="sml, i in socialMediaLinks" :key="i">
        <div :class="[isMobile || isTablet ? 'col' : 'col-5']" class="text-left">
          <menu-item :menuItem="sml" :isMobile="true" @selectItem="selectItem" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import socialMediaLinks from '@/helpers/socialmedia'
import { mapGetters } from 'vuex'
export default {
  name: 'SocialMediaPage',
  components: {
    'menu-item': () => import('@/components/MenuItem.vue')
  },
  computed: {
    ...mapGetters([
      'isMobile',
      'isTablet'
    ])
  },

  data () {
    return {
      socialMediaLinks
    }
  },
  methods: {
    selectItem (sml) {
      window.open(`https://${sml.link}`, '_blank')
    }
  }
}
</script>
<style scoped>
</style>
